<template>
  <LayoutVertical>
    <template #vertical-menu-header="{toggleVerticalMenuActive, toggleCollapsed, collapseTogglerIcon}">
      <ul class="nav navbar-nav flex-row">

        <!-- Logo & Text -->
        <li class="nav-item mr-auto">
          <b-link
            class="navbar-brand"
            to="/"
          >
            <span class="brand-logo">
              <b-img
                :src="logoUrl"
                alt="logo"
              />
            </span>
            <h2 class="brand-text">
              {{ appName }}
            </h2>
          </b-link>
        </li>

        <!-- Toggler Button -->
        <li class="nav-item nav-toggle">
          <b-link class="nav-link modern-nav-toggle">
            <feather-icon
              icon="XIcon"
              size="20"
              class="d-block d-xl-none"
              @click="toggleVerticalMenuActive"
            />
            <feather-icon
              :icon="(collapseTogglerIcon === 'unpinned' ? 'CircleIcon' : 'DiscIcon')"
              size="20"
              class="d-none d-xl-block collapse-toggle-icon"
              @click="toggleCollapsed"
            />
          </b-link>
        </li>
      </ul>
    </template>

    <app-breadcrumb slot='breadcrumb' />
    <router-view />

    <template #navbar="{ toggleVerticalMenuActive }">
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>

    <app-footer slot='footer' />

    <!-- <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    /> -->
  </LayoutVertical>
</template>

<script>
import {
  BLink, BImg,
} from 'bootstrap-vue'
import store from '@/store/index'
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import { $themeConfig } from '@themeConfig'
import AppFooter from '../components/AppFooter.vue'
import AppBreadcrumb from '../components/AppBreadcrumb.vue'
import Navbar from '../components/Navbar.vue'

export default {
  components: {
    // AppCustomizer,
    LayoutVertical,
    Navbar,
    BLink,
    BImg,
    AppFooter,
    AppBreadcrumb,
  },
  data() {
    return {
      // showCustomizer: $themeConfig.layout.customizer,
      logoUrl: null,
      appName: null,
    }
  },
  created() {
    this.getLogo()
  },
  methods: {
    async getLogo() {
      if (this.$store.state.ship2u.company) {
        this.company = this.$store.state.ship2u.company
      } else {
        this.company = await store.dispatch('ship2u/company')
      }
      if (this.company) {
        this.logoUrl = store.state.ship2u.apiHost + this.company.logo
        this.appName = store.state.ship2u.company.title
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.logoUrl = require('@/assets/images/logo/logo.png')
        this.appName = $themeConfig.app.appName
      }
    },
  },
}
</script>

<style lang="scss">
  .brand-logo{
    img {
      max-width: 80px !important;
    }
  }
</style>
